body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
  margin: 0;
}
.Toastify__toast--default {
  background-color: transparent !important;
}
.Toastify__toast {
  box-shadow: unset !important;
  padding: 0 !important;
}

#root {
  /* min-height: 100%; */
}
body,
html {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
html {
  /* background-image: url('./anglo-background-image.png'); */
  /* background: linear-gradient(
    126deg,
    rgb(0, 42, 107) 0%,
    rgb(43, 101, 161) 60%,
    rgb(74, 143, 199) 100%
  ); */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: din-next-medium;
  src: url('./fonts/din-next-lt-pro-medium.woff');
  font-weight: normal;
}
@font-face {
  font-family: din-next;
  src: url('./fonts/din-next-lt-pro.woff');
  font-weight: normal;
}
@font-face {
  font-family: din-next-pro-plus;
  src: url('./fonts/din-next-lt-pro-2.woff');
  font-weight: normal;
}
@font-face {
  font-family: din-next-pro-bold;
  src: url('./fonts/din-next-lt-pro-bold.woff');
  font-weight: normal;
}
